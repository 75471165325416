<template>
  <div
    class="m-auto w-screen h-screen flex flex-col justify-center items-center"
  >
    <h2 class="uppercase text-xl lg:text-5xl font-bold">BVN Verification</h2>

    <template v-if="success">
      <div class="flex justify-center flex-col items-center p-10">
        <img src="@/assets/images/bvn-success.svg" alt="" />
        <div class="h-[30vh] flex flex-col items-center justify-center">
          <p
            class="py-3 border text-center border-oneGreen bg-oneGreen/10 text-oneGreen mb-2 font-bold px-5 rounded w-full text-lg"
          >
            BVN Verification is successful. Please Wait! You will be redirected
            back to where you came from.
          </p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex justify-center flex-col items-center p-10">
        <img src="@/assets/images/bvn-failed.svg" alt="" />

        <div class="h-[30vh] flex flex-col items-center justify-center">
          <p
            class="py-3 border text-center border-red-700 bg-red-100 text-lg text-red-700 mb-2 font-bold px-5 rounded w-full"
          >
            BVN Verification is failed. Please Wait! You will be redirected back
            to where you came from.
          </p>

          <p
            class="underline text-red-600 font-semibold text-lg mt-4 text-center"
          >
            Please try again in 20 minutes time
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "BvnStatus",

  data() {
    return {
      nibbsProceed: false,
      success: false,
      verifying: false,
    };
  },

  created() {
    const status = this.$route.query.st;
    if (!status) {
      this.$router.push({ name: "Staff Bvn Verification" });
    } else if (status === "00") {
      this.success = true;
    } else {
      this.success = false;
    }

    setTimeout(() => {
      window.close();
    }, 20000);
  },
};
</script>

<style scoped></style>
